
import {
  defineComponent,
  onMounted,
  ref,
  useAttrs,
  watch,
  computed,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { formatDateTime, setModuleBCN } from "@/core/directive/function/common";
import {
  PaymentOverview,
  PaymentLogItem,
} from "@/core/directive/interface/payment";
import { getPaymentStatus } from "@/core/directive/function/paymentManagement";

export default defineComponent({
  name: "payment-management-payment-log",
  components: {},
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const attrs = useAttrs();
    const loading = ref<boolean>(true);

    const tableData = ref<Array<PaymentLogItem>>([]);

    watch(
      () => attrs.loading,
      (newValue) => {
        loading.value = newValue as boolean;
      }
    );

    watch(
      () => attrs.baseData,
      (newValue) => {
        const base = newValue as PaymentOverview;
        tableData.value.splice(
          0,
          tableData.value.length,
          ...base.payment_items
        );
      },
      { immediate: true }
    );

    const getStatusClass = computed(() => {
      return (status) => {
        return getPaymentStatus(status);
      };
    });

    onMounted(() => {
      if (!attrs.loading) {
        loading.value = false;
      }
      setModuleBCN(t, route, router);
    });

    return {
      t,
      formatDateTime,
      loading,
      tableData,
      getStatusClass,
    };
  },
});
